.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  background-color: $sidebarColor;
  position: fixed;
  height: 100vh;
  z-index: 5;
  .sidenav-bg:hover {
    background-color: $gray-100;
    border-radius: $border-radius;
  }
}
@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}
