@font-face {
  font-family: 'KoreanFont';
  src: url('../Maplestory\ OTF\ Light.otf') format('otf');
}
$base-primary-color: rgba(245, 245, 245, 1);
$base-secondery-color: rgba(176, 235, 95, 1);
$base-light-color: rgba(157, 157, 157, 1);
body
{
  font-size: 14px;
  background-color: #ffffff;
}
.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
}
.bg-theme-latest
{
  background-color: $base-primary-color;
}
.bg-sec
{
  background-color: $base-secondery-color;
}
.text-sec
{
  color: $base-secondery-color;
}
.input-theme-project
{
  border-radius: 5px;
  background-color: white;
}
.w-25-auto
{
  width: max-content;
}
.theme-radio[type='radio']
{
  accent-color: black;
}
.border-bottom-left-radius
{
  border-bottom-left-radius: 15px;
}
.border-bottom-right-radius
{
  border-bottom-right-radius: 15px;
}
.input-theme
{
  border-radius: 15px;
  padding: 10px 14px;
  background-color: rgba(245, 245, 245, 1);
}
.bg-gray-light
{
  background-color: $base-primary-color !important;
}
.border-radius-25
{
  border-radius: 25px;
}
.card-padding
{
  padding: 34px 24px;
}
.text-light-latest
{
  color: $base-light-color;
}
.text-light-latest a
{
  color: $base-light-color;
  text-decoration: none;
}
.text-light-latest a:hover
{
  color: black;
}
.progress-bar .react-progress-bar-percent
{
  background: $base-secondery-color;
}
.btn-password
{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.theme-checkbox[type="checkbox"]
{
  border-radius: 50%;
  height: 15px;
  width: 15px;
}
.sidebar-navigation
{
  height: 88vh;
  overflow-y: scroll;
  scrollbar-color:#d8d8d8 #fafafa;
  scrollbar-width: thin;
}
.sidebar-text-theme
{
  color: rgba(121, 121, 121, 1);
}
.sidebar-text-theme-active
{
  color: rgba(0, 0, 0, 1);
  background-color: white;
}
.sidebar-text-theme-active:hover
{
  color: rgba(0, 0, 0, 1);
}
.sidebar-text-theme:hover
{
  color: rgba(0, 0, 0, 1);
}
.sidebar-navigation::-webkit-scrollbar {
  width: 8px;
}
.sidebar-navigation::-webkit-scrollbar-track {
  background-color:#fafafa;
}
.sidebar-navigation::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #d8d8d8;
}

.btn-theme-project
{
  background-color: rgb(176, 235, 95);
  color: rgb(0, 0, 0) !important;
  font-weight: 500;
  border-radius: 10px;
  padding: 5px 16px !important;
}
.btn-theme-project:hover
{
  background-color: rgb(0, 0, 0);
  color: white !important;
}
.text-theme-updated
{
  color: rgba(165, 165, 165, 1);
}
.theme-table
{
  // color: rgba(149, 149, 149, 1);
  background-color: rgba(245, 245, 245, 1);
  border-color: white !important;
}
.pagination-links .page-item
{
  margin: 0 10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-links>.page-item.active .page-link
{
  border-color: rgb(176, 235, 95);
  background-color: rgb(0, 0, 0);
  color: white;
  height: 30px; 
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-width: 2px;
}

.pagination-links .page-item a
{
  border: none;
  color: rgb(181, 181, 181);
}
.view-rows
{
  width: 25%;
}
.pagination-arrow .page-link
{
  height: 30px;
  width: 30px;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 235, 235);
  color: rgb(181, 181, 181);
}
.pagination-arrow .page-link:focus
{
  box-shadow: none;
}
.theme-table thead tr
{
  background-color: rgba(235, 235, 235, 1);
  color: rgba(0, 0, 0, 1);
}
.btn-theme-mypage
{
  background-color: rgba(255, 213, 255, 1);
  color: rgb(0, 0, 0) !important;
  font-weight: 500;
  border-radius: 10px;
  padding: 5px 16px !important;
}
.btn-theme-latest
{
  padding: 8px 14px;
  font-size: 20px;
  background-color: $base-secondery-color;
  color: rgba(0, 0, 0, 1);
  border-radius: 15px;
  border: none;
}
.theme-checkbox:checked
{
  background-color: black !important;
  border-color: black !important;
}
.bg-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sigCanvas
{
  height: 100% !important;
  width: 100%;
}
.wrapper {
  position: absolute;
  left: 7%;
  width: 93%;
  top: 10%;
}
.project-card-header
{
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  background-color: rgba(235, 235, 235, 1);
}
.bg-card-light
{
  background-color: #EBEBEB;
}
.bg-light-50-latest
{
  background-color: rgba(235, 235, 235, 1);
}
.right-column
{
  background-color: #f5f5f5;
}
.project-card
{
  margin: 0 12px;
  border-radius: 15px;


}
.wrapper-login {
  position: absolute;
  // right: 7%;
  width: 100%;
  margin-top: 5%;
}
.login-logo
{
  width: 100%;
  max-width: 372px;
}
@page {
  size: auto;
}
.dashboard
{
  margin-right: 290px;
}
@media screen and (max-width: 680px) {
  .wrapper {
    margin: 50px auto 0;
  }
  .w-25-auto
  {
    width: auto;
  }
  .rightsidebar
  {
    position: static !important;
    margin-top: 50px !important;
    height: max-content !important;
  }
}
@media screen and (max-width: 480px) {
  .sigCanvas
  {
    width: 100% !important;
  }
  .rightsidebar
  {
    display: flex;
    justify-content: center;
  }
  .view-rows
  {
    width: 100%;
  }
  .login-logo
  {
    width: 200px;
  }
  .wrapper {
    margin: 0 auto 0;
  }
}
.card {
  box-shadow: $card-box-shadow;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}
.nav-fixed
{
  background-color: whitesmoke;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
}
.text-theme
{
  color: rgb(98 203 247) !important;
}
.logo
{
  color: rgb(98 203 247);
  font-size: 22px;
  font-weight: bolder;
  letter-spacing: 2px;
}
a {
  color: rgb(98 203 247)  ;
  text-decoration: underline;
}
.bg-theme
{
  background-color: rgb(98 203 247) !important;
}
.rightsidebar
{
  z-index: 3;
  padding: 10px;
  position: fixed;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  right: 0;
}
.b-nav
{
  z-index: 2;
  top: 0;
  margin-top: 60px;
  position: fixed;
  width: 100%;
  background-color: rgb(248 248 248);
}
.b-ul li a
{
  color:rgba(0, 0, 0, 0.5) !important;
}
.active
{
  font-weight: bold;
}
.sidebar-card
{
  padding: 8px;
  width: 270px;
  border-radius: 4px;
  border-right:1px solid #d2d2d2 ;
  border-top:1px solid #d2d2d2 ;
  border-bottom:1px solid #d2d2d2 ;
}
.border-left-opened
{
  border-left:4px solid orange ;
}
.border-left-answered
{
  border-left:4px solid #93baf6 ;
}
.border-left-subjects
{
  border-left:4px solid #daf381 ;
}
.border-left-sites
{
  border-left:4px solid #9999c6 ;
}
.border-left-all
{
  border-left:4px solid rgba(244,182,181,255) ;
}
.sidebar-card p
{
  margin-bottom: 0;
}
.sidebar-card h5
{
  margin-bottom: 0;
}
.contentArea
{
  background-color: white;
}
.label
{
  background-color: #eeeff0;
}
.info-form
{
  display: flex;
}
.info-form div
{
  width: 25%;
  display: flex;
  align-items: center;
}
.fs-14
{
  font-size: 14px;
}
.nav-tabs >.nav-link.active
{
  background-color: #efefef !important;
  color: black !important;
}
.nav-tabs > .nav-link
{
  border-radius: 0;
}
.nav-tabs a
{
  color: black !important;
  background-color: white !important;
}
.btn-theme
{
  background-color: rgb(98 203 247) !important;
  border-radius: 0;
  border-color: rgb(98 203 247) !important;
}
.btn-theme-light  
{
  background-color: #eaf9ff !important;
  border-radius: 0;
  border-color: #eaf9ff !important;
}
.btn-theme-light:hover  
{
  background-color: #ddf5ff !important;
  border-radius: 0;
  border-color: #ddf5ff !important;
}
.btn-theme:hover
{
  background-color: rgb(67, 198, 254) !important;
}
.btn-outline-inactive
{
  background-color: #a5a5a5 !important;
  color: rgb(255, 255, 255);
  border-color: #a5a5a5 !important;
}
.btn-outline-inactive:hover
{
  background-color:  #9e9e9e !important;
  color: white;
}
.pagination-links
{
  justify-content: center;
}

.freez-btns
{
  margin-top: 10vh;
}
.locking-tabs > .nav-link.active
{
  background-color: #b0eb5f !important;
  color: black !important;
  font-weight: bold;
}
.locking-tabs > a
{
  color:black !important;
  background-color: rgba(237, 237, 237, 1) !important;
  font-weight: normal;
  border-radius: 15px !important;
  padding: 10px !important;
}
.locking-tabs
{
  border-bottom: 0;
  margin-bottom: 10px;
}
.Db-locking-info
{
  width: 40%;
}
.Db-locking-data
{
  width: 60%;
}
.Db-locking-data div
{
border-bottom:1px solid #d8d8d8;
background-color: #ffffff;
}
.Db-locking-data div p
{
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  font-weight: normal
}
.Db-locking-info div
{
border-bottom: 1px solid #d8d8d8;
background-color: #f5f5f5;
}
.Db-locking-info div p
{
  font-weight: normal;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}
.review-title
{
padding: 10px 1rem;
margin-bottom: 0;
}
.Db-locking-card
{
  width: 35%;
}
.Db-locking-card div
{
  border: 1px solid #d8d8d8;
  background-color: #f5f5f5;
  }
.Db-locking-card div p
{
  font-weight: normal;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}
.Db-card-data
{
  width: 55%;
}
.Db-card-data div
{ 
  border-bottom:1px solid #d8d8d8;
  border-right:1px solid #d8d8d8;
  border-top:1px solid #d8d8d8;
  background-color: #ffffff;
}
.Db-card-data div p
{
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  font-weight: normal
}
.report-card
{
  min-height: 85vh;
  display: flex;
  width: 100%;
  justify-content: center;
}
.data-set-list
{
  height: 50vh;
  overflow-y: scroll;
  font-weight: normal;
  min-width: 200px;
  scrollbar-color:#d8d8d8 #fafafa;
  scrollbar-width: thin;
}
.data-set-list::-webkit-scrollbar {
  width: 8px;
}
.data-set-list::-webkit-scrollbar-track {
  background-color:#fafafa;
}
.data-set-list::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #d8d8d8;
}
.table-responsive
{
  scrollbar-color:rgb(175, 175, 175) #fafafa;
  scrollbar-width: thin;
}
.table-responsive::-webkit-scrollbar {
  height: 10px;
}
.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.table-responsive::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175); 
}
input:focus-visible
{
  border-color: #969696 !important;
  outline: none !important;
}
.review-accordion:first-of-type
{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.review-accordion:first-of-type .accordion-button 
{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.review-accordion:last-of-type
{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.review-accordion:last-of-type .accordion-button 
{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.version > .accordion-button
{
 font-size: 14px !important;
 padding-top: 8px;
 padding-bottom: 8px;
}
.version > .accordion-button::after
{
  background-size:0;
}
.review-acc-body .accordion-body
{
  padding-left: 0;
  padding-right: 0;
}
.review-accordion
{
  background-color: #ffffff;
}
.nav-tabs-reviews .nav-link.active 
{
  background-color: #efefef ;
  border-radius: 0;
  color: black !important;
  font-weight: bold;
}
.nav-tabs-reviews .nav-link
{
  display: flex;
  justify-content: space-between;
  color: black !important;
  font-weight: normal; 
}
.version > .accordion-button:not(.collapsed)
{
  color: black;
  background-color: #efefef;
}
.version > .accordion-button
{
  background-color: #efefef;
}
.version > .accordion-button:focus
{
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.demograpics input
{
  display: block;
  margin: 12px 8px 8px 8px;
}
.checkboxes
{
  padding: 8px;
}
.checkboxes label
{
  font-weight: normal;
}
.main-title
{
 background-color:rgb(98 203 247) ;
 color: white;
}
.visit-title
{
 background-color:rgba(98, 203, 247, 0.22);
}
.w-30
{
  width: 30%;
}
.w-25-custom
{
  width: 25%;
}
.w-20
{
  width: 20%;
}
// #report
// {
//   width: 90%;
// }
.card-details
{
  box-shadow: none;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 15px;
  min-height: 220px;
}
.text-theme-slide-project
{
  color: rgba(113, 113, 113, 1);
  font-weight: 700;
}
.text-theme-slide-btn
{
  color: rgba(113, 113, 113, 1);
}
.text-theme-project-desc
{
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
}
.bg-light-50
{
  background-color: #f2f2f2 !important;
}
.views
{
  color: rgba(184, 184, 184, 1) !important;
  font-weight: 600;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.views.active
{
  font-weight: 600;
  background-color: transparent !important;
  color: rgba(85, 85, 85, 1) !important;
}
.source-table thead tr
{
  background: #DCDCDC; 
  border-radius: 10px;
}
.sidebar.active
{
  background-color: rgba(176, 235, 95, 1) !important;
  color: black !important;
  text-decoration: none;
}
.btn-pink-dropdown:hover
{
  background-color: #FFD5FF;
}
.sidebar
{
padding: 0.5rem 1rem;
background-color: transparent;
border: 1px solid #D2D0DA; 
border-radius: 10px; 
margin-bottom: 6px;
text-decoration: none;
color: black !important;
}
.project-search
{
  border-radius: 10px;
  border-color: rgba(165, 165, 165, 0.3);
  border-width: 1px;
  padding: 5px 8px;
  width: 300px;
}
.monitor
{
  padding: 0.3rem 1rem;
  background-color: white;
text-decoration: none;
color: rgb(88, 88, 88) !important;
margin-right:8px;
border: 1px solid #D2D0DA;
}
.monitor:hover
{
  background-color: rgb(236, 236, 236); 
}.monitor.active
{
  background-color: rgb(98 203 247) !important;
  color: white !important;  
}
.query-input
{
border: 1px solid #D2D0DA;
}
.right-col
{
  background-color: #ffffff;
  padding: 8px;
  font-weight: normal;
  border-bottom: 1px solid #D2D0DA;
}
.left-col
{
  background-color: #F1F2F2;
  padding: 8px;
  font-weight: normal;
  border-bottom: 1px solid #D2D0DA;
  border-right: 1px solid #D2D0DA;
}
  // .query-rows > thead > tr > th
  // {
  //   min-width: 100px;
  // }
.close-query-1
{
  width: 40%;
  background-color: #F1F2F2;
  padding: 12px;
}
.close-query-2
{
  width: 60%;
  background-color: #FFFF;
}
.warning
{
  display: flex;
  margin-top: 8px;
  padding: 5px;
  background-color: #FF3E1D1A;
  color: #FF3E1D;
}
.subject-table > thead > tr > th
{
  min-width: 125px;
}
.action-btn
{
  background-color: transparent;
  color: black;
  border: none;
}
.action-btn::after
{
  display: none;
}
.dropdown-list
{
  font-size: 14px;
}
.left-side
{
  width: 30%;
background-color: #f0f1f2;
height: 100%;
display: flex;
align-items: center;
padding: 5px 10px;
border-width: 1px 0 1px 0;
border-color: #d8d8d8;
border-style: solid;
height: 40px;
}
.right-side
{
  height: 40px;
  width: 70%;
  padding: 5px 10px;
  border-width: 1px 0 1px 1px;
border-color: #d8d8d8;
border-style: solid;
}
.right-side input
{
  width: 100%;
}
.left-side p
{
  margin: 0;
}
.bg-danger-light
{
  background-color: #ffebeb;
  color: #ff0000;
}
.btn-white:focus
{
  box-shadow: none;
  background-color: #D2D0DA;
}
.bg-secondary-light
{
  background-color: #f4f4f4;
}

.bg-schedule-active
{
  background-color: #5456CC;
}

.btn-clip
{
  transform: rotate(45deg);
height: 20px;
display: block;
width: 20px;
}
.fc .fc-col-header-cell-cushion
{
  color: black;
}
.fc-daygrid-day-number
{
  color: black;
}
.page-link
{
  color: rgb(67, 198, 254);
}
.select-theme
{
  width: 178px;
border-radius: 0;
}
.select-theme:focus
{
  border-color: black;
  box-shadow: none;
}
.date-input-theme
{
  width: 178px;
  border-radius: 0;
}
.select-rows
{
  width: 70px;
}
.w-60
{
  width: 60%;
}
.w-40
{
  width: 40%;
}
th
{
  vertical-align: middle;
}
tr
{
  vertical-align: middle;
}
.tab-height
{
  min-height: 100vh;
}
.bg-yellow-light
{
  background-color: #FCFEE8;
}
.bg-light-gray:checked
{
  border-color: #cbc9c9 !important;
  background-color: #cbc9c9 !important;
}
.bg-light-gray
{
  background-color: #efefef !important;
}
.rounded-circle-custom
{
  height: 36px;
  width: 36px;
  border-radius: 50%;
  padding: 0;
}
.border-primary-light
{
  border-color: rgb(67, 198, 254) !important;
}
.login-card
{
  height: 75vh;
  align-items: center;
  justify-content: center;
}
.mw-200
{
  min-width: 200px;
}
.signature-table > tbody > tr > td{
  min-width: 150px;
  height: 90px;
}
.crf-accordion button:not(.collapsed)::after
{
  position: absolute;
  left: 5px;
  bottom: 12px;
}
.crf-accordion button
{
  padding-left: 2rem;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  font-size: 14px;
}
.roles-accordion
{
  margin: 8px;
}
.roles-accordion button
{
  color: black;
  border-radius: 10px !important; 
  box-shadow: none;
  border: 1px solid #D6D6D6; 
  background-color: #F2F2F2;
  padding: 10px 16px;
  font-size: 14px;
}
.role-select
{
  border-radius: 10px !important; 
  background-color: #F2F2F2;
  padding: 8px 16px;
  font-size: 14px;
}
.roles-name
{
  background-color: white;
  border: 1px solid #D2D0DA; 
  border-radius: 10px; 
  padding: 6px;
  font-weight: normal;
}
.btn-role-save
{
  border: none;
  background-color: $base-secondery-color;
  color: black;
}
.btn-role-save:hover
{
  background-color: black;
  color: white;
}
.btn-role-cancel
{
  background-color: rgba(209, 249, 255, 1);
  color: black;
}
.btn-role-cancel:hover
{
  background-color: black;
  color: white;
}
.btn-role-del
{
  background: #ED414A; 
  color: white;
}
.btn-role-del:hover
{
  background-color: #fc313b;
}
.role-select-name
{
  border-radius: 10px; 
  background-color: white;
  padding: 6px;
}
.crf-accordion button::after
{
  position: absolute;
  left: 5px;
  background-size: 1rem;
}
.roles-accordion button::after
{
  position: absolute;
  left: 5px;
  display: none;
  background-size: 1rem;
}
.crf-accordion button:not(.collapsed)
{
  color: black;
  box-shadow: none;
  background-color: rgb(234, 249, 255);
}
.roles-accordion button:not(.collapsed)
{
  color: black;
  border-radius: 10px !important; 
  box-shadow: none;
  border: 1px solid #D6D6D6; 
  background-color: #F2F2F2;
  padding: 10px 16px;
}
.crf-accordion button:focus
{
  box-shadow: none;
}
.roles-accordion button:focus
{
  box-shadow: none;
}
.crf-accordion-item:first-of-type,.crf-accordion-item:last-of-type
{
  border-radius: 0;
  border-color: #efefef;
}
.site-card
{
  background-color: #F5F5F5;;
}
.crf-accordion-item 
{
  background-color: rgba(245, 245, 245, 1);
  border-color: #efefef;
  border: none;
}
.crf-P
{
  background-color:rgb(98 203 247) ;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crf-C{
  background-color:#ffcb00 ;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crf-accordion-body .accordion-body
{
  padding: 3px 10px;
}
.crf-btn:focus
{
  box-shadow: none;
}
.vital-signs > tr
{
  vertical-align: bottom;
}
.score-type > tr
{
  vertical-align: top;
}
.vital-signs > tr > td
{
  border: 0;
}
.vital-signs > tr > td > .td-border
{
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem ;
}
.w-55
{
  width: 55px;
}
.btn:focus
{
  box-shadow: none;
}
.icon-setting
{
  right: 5%;
height: 100%;
}
.w-15
{
  width: 15%;
}
.w-13
{
  width: 13%;
}
.w-88
{
  width: 88%;
}

input:focus
{
  box-shadow: none !important;
}
.role-navi
{
  background-color: #F2F2F2;
  border: 1px solid #D6D6D6; 
  border-radius: 10px !important;
  color: black !important;
}
.role-navi > a
{
  color: black !important; 
}
.role-navi >.active
{
  background-color: rgba(176, 235, 95, 1) !important;
  color: black;
} 
.btn-role
{
  background-color: #F2F2F2;
  border: 1px solid #D6D6D6; 
  border-radius: 10px;
  color: black;
}
.btn-role:hover
{
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1); 
  color: white;
}
.navi
{
  border-radius: 0 !important;
  background-color: white !important;
  color: black !important;
}
.navi > a
{
  color: black !important; 
}
.navi >.active
{
  background-color: #f2f2f2 !important;
  color: white;
  border-radius: 0;
} 
.form-check-input:checked {
  background-color: $base-secondery-color;
  border-color: $base-secondery-color;
}
.z-index-1
{
  position: relative;
  z-index: 1;
}
.copymodal-overflow
{
  height: 550px;
  overflow-y: auto;
  scrollbar-color:#d8d8d8 #fafafa;
  scrollbar-width: thin;
}
.copymodal-overflow::-webkit-scrollbar {
  width: 8px;
}
.copymodal-overflow::-webkit-scrollbar-track {
  background-color:#fafafa;
}
.copymodal-overflow::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #d8d8d8;
}

.table-responsive {
	min-height: 320px;
}
.views-icons .nav-link.active
{
  color: black !important;
}
.pagination-btn
{
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(235, 235, 235, 1) !important;
  color: rgba(181, 181, 181, 1) !important;
}
.overflow-x
{
  overflow-x: auto;
  scrollbar-color:#d8d8d8 #fafafa;
  scrollbar-width: thin;
}
.ongoing
{
  height: 8px;
  width: 8px;
  background-color: rgba(30, 124, 91, 1);
  display: inline-block;
  margin-right: 6px;
}
.completed
{
  height: 8px;
  width: 8px;
  background-color:rgba(255, 175, 54, 1);
  display: inline-block;
  margin-right: 6px;
}
.text-light-gray
{
  color: #32475C61;
}
@media screen and (max-width: 480px) {
  .dashboard {
    margin-right: 0;
  }
  .w-25-custom
  {
    width: 100%;
  }
  .wrapper
  {
    left: 0;
    width: 100%;
  }
}